<template>
  <b-modal
    :id="modalId"
    :title="title"
    size="md"
    centered
    hide-footer
    scrollable
    no-close-on-backdrop
    no-close-on-esc
  >
    <div v-if="invoiceData" class="invoice-detail-modal">
      <div class="d-flex justify-content-between">
        <p>
          <b>NGÀY XUẤT HOÁ ĐƠN</b><br />
          {{ invoiceDate }}
        </p>
        <p>
          <b>KHÁCH HÀNG</b><br />
          {{ invoiceData.customerName }}
        </p>
        <p>
          <b>TRẠNG THÁI</b><br />
          <!-- {{ invoiceData.status !== invoiceStatus.Canceled ? 'Đã thanh toán' : 'Đã hủy'}} -->
          {{getTextStatus(invoiceData)}}
        </p>
      </div>
      <div v-for="item in invoiceData.sessions" :key="item._id">
        <hr />
        <p>
          <b>{{ item.name }} ({{ item.total }})</b>
        </p>
        <p class="text-underline">DỊCH VỤ: ({{ item.subTotal }})</p>
        <div v-for="product in item.orderProduct" :key="product._id">
          <p class="d-flex justify-content-between mb-0">
            {{ product.name }} <span>{{ product.price }}</span>
          </p>
        </div>
        <p class="text-underline" v-if="item.promos.length">
          GIẢM GIÁ: ({{ item.discountTotal }})
        </p>
        <div v-for="promo in item.promos" :key="promo._id">
          <p class="d-flex justify-content-between">
            {{ promo.name }} <span>{{ promo.currencyValue }}</span>
          </p>
        </div>
        <p class="text-underline" v-if="item.tip">TIP: ({{ item.tip }})</p>
      </div>
      <hr />
      <p><b>THÔNG TIN THANH TOÁN</b></p>
      <div class="d-flex justify-content-between">
        <p class="text-underline no-outline" v-b-toggle="invoiceData._id">
          <b>GIFT CARD:</b> <span>({{ gCTotal }})</span>
        </p>
        <p class="text-underline">
          <b>TRẢ THẺ:</b> <span>({{ invoiceData.paymentMethods.card }})</span>
        </p>
        <p class="text-underline">
          <b>TIỀN MẶT:</b> <span>({{ invoiceData.paymentMethods.cash }})</span>
        </p>
      </div>
      <b-collapse :id="invoiceData._id" v-if="gCTotal">
        <hr />
        <p class="d-flex justify-content-between mb-0 text-underline">
          <span><b>GiftCode</b></span> <span><b>Giá trị</b></span>
        </p>
        <div v-for="gc in invoiceData.paymentMethods.giftCard" :key="gc._id">
          <p class="d-flex justify-content-between mb-0">
            {{ gc.code }} <span>{{ gc.value }}</span>
          </p>
        </div>
      </b-collapse>
    </div>
    <div v-else class="d-flex justify-content-center">
      <section class="loading">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
import { getInvoice } from "@/api/invoice";
import { InvoiceStatus } from "./../../constans"

export default {
  name: "invoice-detail-modal",
  props: ["invoiceId"],

  data() {
    return {
      invoiceData: null,
      invoiceStatus: InvoiceStatus
    };
  },

  watch: {
    invoiceId(val) {
      if (!val) return;

      getInvoice(val).then(res => {
        this.invoiceData = res.data;
        this.openModal();
      });
    }
  },

  methods: {
    openModal() {
      this.$bvModal.show(this.modalId);
    },

    getTextStatus(data) {
      let text = 'Đã thanh toán'
      if (data.status === this.invoiceStatus.Canceled && data.invoiceNumber) {
        text = 'Đã hủy sau khi thanh toán bởi '+ ( data.deletedBy[0].email )
      } else if (data.status === this.invoiceStatus.Canceled && !data.invoiceNumber) {
        text = 'Đã hủy'
      }
      return text
    }, 
  },

  created() {
    this.modalId = `modal-${new Date().getTime()}`;
    this.$root.$on("bv::modal::hidden", () => {
      this.invoiceData = null;
    });
  },

  computed: {
    title() {
      return `MÃ HOÁ ĐƠN: ${this.invoiceData?.invoiceNumber || "xxxx-xxxx"}`;
    },

    sessionName() {
      return this.invoiceData
        ? this.invoiceData.sessions.map(s => s.name)?.join(", ")
        : "";
    },

    invoiceDate() {
      return this.invoiceData
        ? moment(this.invoiceData.sessions[0].purchasedDate).format(
            "DD/MM/YYYY - HH:mm:ss"
          )
        : "DD/MM/YYYY - HH:mm:ss";
    },

    gCTotal() {
      return this.invoiceData
        ? this.invoiceData.paymentMethods.giftCard.reduce(
            (t, g) => (t += g.value),
            0
          )
        : 0;
    }
  }
};
</script>

<style lang="scss">
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.invoice-detail-modal p {
  margin-bottom: 0;
  color: black;
}
</style>
