<template>
  <div>
    <OutletSelect @change="onSelectOutlet"></OutletSelect>
    <KTPortlet headSize="md">
      <template v-slot:title>
        <h3 class="kt-portlet__head-title mr-3">Danh sách hóa đơn</h3>
        <b-form-datepicker
          calendar-width="100%"
          v-model="currentDate"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            weekday: 'short',
          }"
          v-bind="labelVN"
          locale="vie"
        >
        </b-form-datepicker>
        <div
          @click="currentDate = null"
          class="btn-reset-date"
          :class="{ show: currentDate }"
        >
          <i class="flaticon2-reload-1"></i>
        </div>
      </template>

      <template v-slot:body>
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-6 col-md-6">
            <dl class="d-flex justify-content-between align-items-center">
              <dt class="pr-4 flex-grow-0">Loại hóa đơn</dt>
              <dd class="modal-session flex-grow-1">
                <b-form-select
                  :options="typeInvoice"
                  v-model="selectedTypeInvoice"
                ></b-form-select>
              </dd>
            </dl>
          </div>
          <div class="col-12 col-lg-6 col-xl-6 col-md-6">
            <SearchInput
              pHolder="Nhập để tìm kiếm..."
              @search="onSearch"
            ></SearchInput>
          </div>
        </div>
        <div class="table-invoice mt-3">
          <b-table
            v-if="items.length"
            head-variant="light"
            responsive
            striped
            :items="items"
            :fields="fields"
            :sticky-header="true"
          >
            <template
              v-if="selectedTypeInvoice === 0"
              v-slot:cell(sessions)="data"
            >
              <span>{{ getSessionName(data.value) }}</span>
            </template>
            <template v-slot:cell(invoiceNumber)="data">
              <!-- <span >{{ data.item.status !== invoiceStatus.Canceled ? data.item.invoiceNumber : 'Đã hủy'}}</span> -->
              <span>{{ getTextStatus(data.item) }}</span>
            </template>

            <template
              v-if="selectedTypeInvoice === 1"
              v-slot:cell(purchasedDate)="data"
            >
              <span>{{ formatDate(data.value) }}</span>
            </template>
            <template
              v-if="selectedTypeInvoice === 1"
              v-slot:cell(status)="data"
            >
              <span>
                <b-badge
                  v-if="data.value === 0"
                  variant="info"
                  class="btn-status"
                  >Chưa bán</b-badge
                >
                <b-badge
                  v-else-if="data.value === 1"
                  variant="warning"
                  class="btn-status"
                  >Chờ thanh toán</b-badge
                >
                <b-badge
                  v-else-if="data.value === 2"
                  variant="success"
                  class="btn-status"
                  >Đã Bán</b-badge
                >
                <b-badge
                  v-else-if="data.value === 3"
                  variant="danger"
                  class="btn-status"
                  >Đã sử dụng hết</b-badge
                >
              </span>
            </template>
            <template
              v-if="selectedTypeInvoice === 1"
              v-slot:cell(paymentMethod)="data"
            >
              <span>{{ giftCardPaymentMethod[data.value] }}</span>
            </template>

            <template v-slot:cell(_id)="data">
              <b-button
                size="sm"
                variant="info"
                @click="
                  selectedTypeInvoice === 0
                    ? viewInvoiceDetail(data.value)
                    : onViewGiftCardInvoice(data.item)
                "
                class="font-weight-bold pt-1 pb-0"
              >
                <i class="flaticon2-information"></i> Chi tiết
              </b-button>
            </template>
          </b-table>

          <div v-else class="d-flex justify-content-center">
            <section v-if="loading" class="loading align-items-center">
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
            </section>
            <div class="loading align-items-center" v-else>
              <div class="text-center">
                <h1><i class="flaticon2-open-box"></i></h1>
                <h5>Không tìm thấy dữ liệu thích hợp</h5>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-12 d-flex justify-content-center"
          v-if="rows > itemPage"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="itemPage"
          ></b-pagination>
        </div>
      </template>
    </KTPortlet>

    <invoice-detail-modal :invoiceId="invoiceId"></invoice-detail-modal>
  </div>
</template>

<style lang="scss">
@import "./Invoice.scss";
.loading {
  height: calc(100vh - 505px);
}
.icon-md {
  font-size: 50px;
}
.btn-reset-date {
  display: flex;
  padding: 3px;
  font-size: 18px;
  min-width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  opacity: 0;
  transition: opacity 0.1s;
  &.show {
    opacity: 1;
    transition: opacity 0.75s;
  }
}
.b-form-datepicker {
  width: 160px;
}
.kt-portlet__head-title {
  white-space: nowrap;
}
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import SearchInput from "@/components/SearchInput.vue";
import InvoiceDetailModal from "@/components/modals/InvoiceDetail.vue";

import { getAllInvoice } from "@/api/invoice";
import { getGiftCardInvoice } from "@/api/gift-card";
import Loader from "@/common/loader";
import moment from "moment";
import Swal from "sweetalert2";
import { LABEL_VN, VARIANTS, InvoiceStatus } from "./../../constans";

export default {
  components: {
    OutletSelect,
    KTPortlet,
    SearchInput,
    InvoiceDetailModal,
  },
  data() {
    return {
      outletId: "",
      searchText: "",
      typeInvoice: [
        {
          value: 0,
          text: "Hóa đơn lượt làm việc",
        },
        {
          value: 1,
          text: "Hóa đơn Giftcard",
        },
      ],
      selectedTypeInvoice: null,
      currentPage: 1,
      rows: 0,
      items: [],
      invoiceId: "",
      loading: false,
      labelVN: LABEL_VN,
      variants: VARIANTS,
      currentDate: null,
      itemPage: 10,
      invoiceStatus: InvoiceStatus,
    };
  },
  watch: {
    currentDate() {
      this.getData();
    },

    currentPage(val) {
      this.getData();
    },

    selectedTypeInvoice(newVal, oldVal) {
      this.getData();
    },

    searchText() {
      this.getData();
    },
  },
  created() {
    this.$store.dispatch("titleBreadcrumb", "Quản lý hóa đơn");
  },
  methods: {
    getTextStatus(data) {
      let text = data.invoiceNumber;
      if (data.status === this.invoiceStatus.Canceled && data.invoiceNumber) {
        text = "Đã hủy sau khi thanh toán";
      } else if (
        data.status === this.invoiceStatus.Canceled &&
        !data.invoiceNumber
      ) {
        text = "Đã hủy";
      }
      return text;
    },
    getData() {
      this.items = [];
      setTimeout(() => {
        this.selectedTypeInvoice === 0
          ? this.getInvoices()
          : this.getGCInvoice();
      }, 1);
    },
    getGCInvoice() {
      this.loading = true;
      const qr = {
        outletId: this.outletId,
        currentPage: this.currentPage,
        searchText: this.searchText,
      };

      this.currentDate && (qr.date = this.currentDate);

      getGiftCardInvoice(qr)
        .then((res) => {
          this.items = res.data.data;
          this.rows = res.data.total;
        })
        .finally(() => (this.loading = false));
    },
    getInvoices() {
      this.loading = true;
      const qr = {
        outletId: this.outletId,
        currentPage: this.currentPage,
        searchText: this.searchText,
      };

      this.currentDate && (qr.date = this.currentDate);

      getAllInvoice(qr)
        .then((res) => {
          this.items = res.data.data;
          this.rows = res.data.total;
        })
        .finally(() => (this.loading = false));
    },
    onSelectOutlet(event) {
      this.outletId = event;
      const tmp = this.selectedTypeInvoice;
      this.selectedTypeInvoice = null;
      this.selectedTypeInvoice = tmp || 0;
      this.getInvoices();
    },
    onViewUserReport(item) {
      this.$router.push({ name: "invoice-detail", params: { id: item._id } });
    },

    onSearch(evt) {
      this.searchText = evt;
    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    onViewGiftCardInvoice(item) {
      Swal.fire({
        title: `Hoá đơn GiftCard ${item.code}`,
        html: `
                    <div class="px-3">
                        <dl class="d-flex justify-content-between">
                            <dt>Người bán</dt>
                            <dd>${item.seller.name}</dd>
                        </dl>
                        ${
                          item.customer?.name
                            ? `<dl class="d-flex justify-content-between">
                                <dt>Tên khách hàng</dt>
                                <dd>${item.customer.name}</dd>
                            </dl>`
                            : ""
                        }
                        <dl class="d-flex justify-content-between">
                            <dt>Ngày bán</dt>
                            <dd>${moment(item.purchasedDate).format(
                              "DD-MM-YYYY"
                            )}</dd>
                        </dl>
                        <dl class="d-flex justify-content-between">
                            <dt>Phương thức thanh toán</dt>
                            <dd>${
                              this.giftCardPaymentMethod[item.paymentMethod]
                            }</dd>
                        </dl>
                        <dl class="d-flex justify-content-between">
                            <dt>Giá trị GiftCard</dt>
                            <dd>${item.price}</dd>
                        </dl>
                    </div>
                `,
      });
    },

    getSessionName(sessions) {
      return sessions.map((s) => s.name).join(", ");
    },

    viewInvoiceDetail(_id) {
      this.invoiceId = _id;
    },
  },
  computed: {
    giftCardPaymentMethod() {
      return ["Thẻ", "Tiền mặt", "Tặng"];
    },

    fields() {
      return this.selectedTypeInvoice === 0
        ? [
            {
              key: "invoiceNumber",
              label: "Mã Hóa đơn",
              stickyColumn: true,
              variant: "light",
            },
            {
              key: "date",
              label: "Ngày",
            },
            {
              key: "customerName",
              label: "Tên khách",
            },
            {
              key: "sessions",
              label: "Lượt liên quan",
            },
            {
              key: "total",
              label: "Tổng tiền",
            },
            {
              key: "_id",
              label: "",
              class: "text-right",
            },
          ]
        : [
            {
              key: "code",
              label: "Mã Gift Card",
              stickyColumn: true,
              variant: "light",
              class: "customer-code",
            },
            {
              key: "purchasedDate",
              label: "Ngày bán",
            },
            {
              key: "status",
              label: "Trạng thái",
            },
            {
              key: "seller.name",
              label: "Người bán",
            },
            {
              key: "price",
              label: "Tổng tiền",
            },
            {
              key: "paymentMethod",
              label: "Phương thức thanh toán",
            },
            {
              key: "_id",
              label: "",
              class: "text-right",
            },
          ];
    },
  },
};
</script>
